<template>
  <div class="room-locked main-container">
    <div class="container--slim">
      <h1>{{ $t('conference_room_locked_headline') }}</h1>
      <div>
        {{ $t('conference_room_locked_text') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RoomLocked',
};

</script>
