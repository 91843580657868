<template>
  <main class="entry main-container">
    <locale-changer />

    <!-- Room Locked / Room not started overlays -->
    <template v-if="showNotStarted">
      <transition name="modal">
        <div
          v-if="showNotStarted"
          class="overlay"
        >
          <not-started v-if="error === ENLIST_ERRORS.ROOM_NOT_STARTED" />
          <room-locked v-if="error === ENLIST_ERRORS.ROOM_LOCKED" />
        </div>
      </transition>
    </template>

    <template v-else>
      <!-- Intro Video -->
      <intro-modal
        v-if="hasIntroVideo"
        v-show="showIntroModal"
        ref="introModal"
        :video-id="(introVideo || {}).dataId"
        :skip-time-out-end="3"
        @close="goToWebinar"
      />
      <!-- Hardware Test / Nickname form -->
      <template v-if="participation && room && !showIntroModal">
        <errors :errors="displayErrors" />

        <div class="entry__inner">
          <h1 class="entry__welcome">
            {{ welcomeHeadline }}
          </h1>

          <p class="entry__intro">
            {{ welcomeIntro }}
          </p>


          <hardware-test
            v-if="!isConnected && initialized"
            class="entry__hardware-test"
            :nickname="customNickname"
            :can-enable-video="canSendVideo"
            :can-enable-audio="canSendAudio"
            :show-video-quality="false"
            :show-nickname="canChangeName"
            :show-video="videoSupported"
            :show-audio="audioSupported"
            :video-enabled="videoEnabled"
            :audio-enabled="audioEnabled"
            :video-block-heading="$t('hardware_test_video_settings_title')"
            :audio-block-heading="$t('hardware_test_audio_settings_title')"
            :chat-block-heading="$t('hardware_test_chat_settings_title')"
            :video-block-hint="$t('hardware_test_video_block_hint')"
            @on-nickname-change="val => customNickname = val"
            @on-toggle-audio="val => updateAudioEnabled({ enabled: val, ignoreSettings: true })"
            @on-toggle-video="val => updateVideoEnabled({ enabled: val, ignoreSettings: true })"
            @submit="onSubmit"
          />

          <app-button
            :id="getGtmId('entry_submit')"
            class="entry__submit"
            :label="entryLabel"
            type="secondary"
            arrow
            @click="onSubmit"
          />
        </div>
      </template>
    </template>
  </main>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import Vue from 'vue';
import AppButton from '@/components/common/Button';
import Errors from '@/components/common/Errors';
import IntroModal from '@/components/IntroModal';
import LocaleChanger from '@/components/common/LocaleChanger';
import NotStarted from '@/components/NotStarted';
import RoomLocked from '@/components/RoomLocked';

import request from '@/api/api';
import ENLIST_ERRORS from '@/util/errors';

const RELOAD_INTERVAL = 10000;

export default {
  name: 'Entry',

  components: {
    AppButton,
    // TODO unify error overlay component
    Errors,
    IntroModal,
    LocaleChanger,
    NotStarted,
    RoomLocked,
  },

  data: () => ({
    error: '',
    validationErrors: {},
    customNickname: '',
    notStartedInterval: '',
    initialized: false,
    showIntroModal: false,
    ENLIST_ERRORS,
  }),

  computed: {
    ...mapState('AWCView', ['loaded3q']),
    ...mapState('AWCConversation', {
      isConnected: state => !!state.conversation,
    }),
    ...mapState('AWCMedia', ['mediaError']),
    ...mapState('AWCUser', ['audioEnabled', 'videoEnabled']),
    ...mapState(['participation', 'room', 'validationToken']),

    ...mapGetters([
      'isPresenter', 'webinarLoaded', 'roomStarted',
      'canSendVideo', 'canSendAudio', 'canChangeName',
      'videoSupported', 'audioSupported',
      'videosActive', 'introVideo',
    ]),

    entryLabel() {
      return this.isPresenter && !this.roomStarted
        ? this.$t('entry_start_conference_button_label') : this.$t('entry_enter_conference_button_label');
    },

    welcomeHeadline() {
      let message = this.$t('entry_welcome_message');
      if (this.safeGet(this.room, 'name')) {
        message = `${message} "${this.room.name}"`;
      }
      if (this.isPresenter && this.safeGet(this.participation, 'nickname')) {
        message = `${message}, ${this.participation.nickname}`;
      }
      return message;
    },

    welcomeIntro() {
      if (this.canSendVideo && this.canSendAudio) return this.$t('entry_intro_media_initially_active');
      if (this.canSendVideo && !this.canSendAudio) return this.$t('entry_intro_camera_initially_active');
      if (!this.canSendVideo && this.canSendAudio) return this.$t('entry_intro_mic_initially_active');
      if (this.videoSupported && this.isAudioSupported) return this.$t('entry_intro');
      return this.isAudioSupported ? this.$t('entry_intro_audio_only') : this.$t('entry_intro_no_permissions');
    },

    nameMandatory() {
      return this.safeGet(this.room, 'settings.nicknameIsMandatory', false);
    },

    displayErrors() {
      return [
        ...Object.values(this.validationErrors),
        this.error,
        (this.mediaError || {}).message,
      ].filter(e => !!e);
    },

    showNotStarted() {
      return [ENLIST_ERRORS.ROOM_LOCKED, ENLIST_ERRORS.ROOM_NOT_STARTED].includes(this.error);
    },

    hasIntroVideo() {
      return !!this.introVideo && this.videosActive && !this.isPresenter;
    },
  },

  watch: {
    // Sets customNickname as the last participation's nickname
    participation: {
      immediate: true,
      handler(val) {
        this.customNickname = this.safeGet(val, 'nickname');
      },
    },

    room: {
      immediate: true,
      handler(val) {
        if (val) document.title = val.name;
      },
    },

    error: {
      immediate: false,
      handler(error) {
        switch (error) {
          case ENLIST_ERRORS.ROOM_LOCKED: document.title = this.$t('conference_room_locked_headline'); break;
          case ENLIST_ERRORS.ROOM_NOT_STARTED: document.title = this.$t('conference_not_started_headline'); break;
          default: document.title = this.$route.meta.title;
        }
      },
    },
  },

  mounted() {
    this.error = this.$route.params.error;

    if (document.getElementsByTagName('main')[0]) {
      Vue.browserUpdate.appendContainer(document.getElementsByTagName('main')[0]);
    }
  },

  destroyed() {
    clearInterval(this.notStartedInterval);
  },

  async created() {
    this.setEnlistToken(this.$route.query.token);
    await this.enlistParticipation({ token: this.$route.query.token })
      .then(() => this.onEnlistSuccess())
      .catch(e => this.handleError(e));
  },

  methods: {
    ...mapActions('AWCUser', ['updateAudioEnabled', 'updateVideoEnabled']),
    ...mapMutations('AWCConversation', ['setVideoSupported', 'setAudioSupported']),
    ...mapMutations(['setEnlistToken']),
    ...mapActions(['enlistParticipation', 'updateParticipation', 'clearConferenceData']),

    validateForm() {
      this.validationErrors = {};

      if (!this.customNickname && this.nameMandatory) {
        this.$set(this.validationErrors, 'nickname', this.$t('entry_errors_nickname_missing'));
      }

      return !this.validationErrors.nickname;
    },

    async onSubmit() {
      if (!this.validateForm()) {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        return;
      }

      if (this.customNickname !== this.safeGet(this.participation, 'nickname')) {
        const changed = await this.changeNickname(this.customNickname);
        if (!changed) return;
      }

      const token = this.validationToken;
      if (process.env.VUE_APP_VALIDATION_ACTIVE === 'true' && token) {
        await request.$http.request({
          method: 'GET',
          url: `burn_attempt/${token}`,
          baseURL: process.env.VUE_APP_VALIDATION_URL,
        }).catch(() => this.$router.push({
          name: 'ThankYou',
        }));
      }

      if (this.hasIntroVideo && this.loaded3q) {
        this.showIntroModal = true;
        this.$refs.introModal.play();
        return;
      }

      this.goToWebinar();
    },

    goToWebinar() {
      this.$router.push({
        name: 'Webinar',
        params: { roomID: this.room.roomId },
        query: { debug: this.$route.query.debug },
      });
    },

    async changeNickname() {
      this.log('Entry: change nickname', this.customNickname);

      const { data: { nicknameFree } } = await request.checkNicknameFree(this.$route.query.token, this.customNickname);

      if (!nicknameFree) {
        this.logError('Entry: Custom nickname in use', this.customNickname);
        this.$set(this.validationErrors, 'nickname', this.$t('entry_errors_nickname_duplicate'));
        return false;
      }

      try {
        await this.updateParticipation({ nickname: this.customNickname });
      } catch (error) {
        this.handleError(error);
        return false;
      }

      return true;
    },


    onEnlistSuccess() {
      this.setEnlistToken(this.$route.query.token);
      this.setVideoSupported(this.videoSupported);
      this.setAudioSupported(this.audioSupported);
      this.initialized = true;
      this.error = false;
      clearInterval(this.notStartedInterval);

      if (this.$route.query.autojoin) {
        this.log('Entry: autojoin...');
        this.onSubmit();
      }
    },

    notStartedHandler() {
      this.notStartedInterval = setInterval(() => {
        this.enlistParticipation({ token: this.$route.query.token })
          .then(() => this.onEnlistSuccess())
          .catch(e => this.handleError(e));
      }, RELOAD_INTERVAL);
    },

    handleError(e) {
      const status = this.safeGet(e, 'response.status');
      const description = this.safeGet(e, 'response.data.description') || '';

      if (status === 403) {
        if (description.includes('locked')) this.error = ENLIST_ERRORS.ROOM_LOCKED;
        else this.error = ENLIST_ERRORS.ROOM_NOT_STARTED;
        if (!this.notStartedInterval) this.notStartedHandler();
        return;
      }

      let showBack = false;
      let error = false;

      switch (status) {
        case 401: // 401 - meeting not found
          error = this.$t(ENLIST_ERRORS.NOT_FOUND);
          this.logError('Entry: meeting not found');
          break;
        case 409: // 409 - nickname conflict
          error = this.$t(ENLIST_ERRORS.NICKNAME_DUPLICATE);
          this.logError('Entry: nickname conflict');
          break;
        default:
          showBack = true;
          this.logError('Entry: unknown error', e);
          break;
      }
      this.$router.push({ name: 'Error', params: { showBack, error } });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_app.scss';

.entry {

  .locale-changer {
    display: inline-block;
    position: absolute;
    right: .5rem;
    top: .5rem;
    z-index: 2;

    @include breakpoint(1260px) {
      right: calc((100vw - #{$container-width}) / 2);
      top: 1rem;
    }
  }

  &__hardware-test {
    margin: 2rem auto;
  }

  &__submit {
    margin: 1rem auto;
  }

  &__inner {
    padding: 1rem;
    position: relative;
    text-align: center;
    margin: 2rem auto;
    max-width: 100vw;
    width: 100%;
  }

  &__welcome {
    @extend h2;
    text-transform: uppercase;

    @include breakpoint(medium) {
      font-size: 30px;
    }
  }

  &__intro {
    @extend .container--slim;
  }
}

.overlay {
  padding: 2rem;
}
</style>
