<template>
  <div
    v-if="locales.length > 1"
    class="locale-changer"
  >
    <app-select
      :id="getGtmId('locale_selector')"
      :value="$i18n.locale"
      :options="supportedLocales"
      label-property="name"
      value-property="code"
      @input="switchLocale"
    />
  </div>
</template>

<script>
import AppSelect from '@/components/common/Select';

export default {
  name: 'LocaleChanger',

  components: {
    AppSelect,
  },

  data: () => ({
    locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALES.split(','),
  }),

  computed: {
    supportedLocales() {
      return this.locales.map(code => ({
        code,
        name: this.$t(code),
      }));
    },
  },

  methods: {
    switchLocale(locale) {
      if (this.$i18n.locale === locale.code) return;
      this.$i18n.locale = locale.code;
    },
  },
};
</script>

<style lang="scss" scoped>

.locale-changer {
  cursor: pointer;

  /deep/ .custom-select {
    > .selected,
    > .items {
      border: 0;
      background: none;
      text-transform: uppercase;
    }
    > .items {
      background: white;
      div {
        color: $color-darkgrey;
      }
    }
  }
}
</style>
