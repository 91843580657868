<template>
  <div class="not-started main-container">
    <div class="container--slim">
      <h1>{{ $t('conference_not_started_headline') }}</h1>
      <div>
        {{ $t('conference_not_started_text') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotStarted',
};

</script>
