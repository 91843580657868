<template>
  <modal
    class="intro-modal"
    width="large"
    centered
    dark
    transparent
    :closable="false"
  >
    <div class="intro-video">
      <video-3q-player
        ref="introvod"
        :data-id="videoId"
        show-fullscreen
        :playing="playing"
        @mediaComplete="$emit('close')"
        @mediaDurationChange="onDurationchange"
        @mediaTimeChange="onTimeChange"
      />
    </div>

    <transition name="modal">
      <div
        :style="{ visibility: showClose ? 'visible' : 'hidden' }"
        class="intro-modal__buttons"
      >
        <app-button
          :id="getGtmId('intro_modal_continue')"
          arrow
          small
          type="secondary"
          :label="$t('intro_modal_continue')"
          @click="$emit('close')"
        />
      </div>
    </transition>
  </modal>
</template>


<script>
import Modal from '@/components/common/Modal';
import AppButton from '@/components/common/Button';

export default {

  components: {
    Modal,
    AppButton,
  },

  props: {
    videoId: {
      type: String,
      default: '',
    },
    skipTimeOutEnd: {
      type: Number,
      default: 0,
    },
  },

  data: () => ({
    showClose: false,
    duration: 120,
    generalTimeOut: 180,
    playing: false,
  }),

  methods: {
    play() {
      this.playing = true;
      this.$nextTick(() => this.$refs.introvod.restart());
      setTimeout(() => this.showClose = true, this.generalTimeOut * 1000);
    },

    onTimeChange(seconds) {
      if (parseInt(seconds, 0) + this.skipTimeOutEnd >= this.duration) this.showClose = true;
      else this.showClose = false;
    },

    onDurationchange(duration) {
      this.duration = duration || 120;
    },
  },
};
</script>


<style lang="scss" scoped>
  @import '@/assets/scss/_app.scss';

  .intro-modal {

    &__buttons {
      // position: absolute;
      // top: 50%;
      // left: 50%;
      // z-index: 10000;
      // transform: translate(-50%, -50%);
      margin: 1rem 0;

      button {
        margin: auto;
      }
    }

    .intro-video {
      height: calc(100vh - 6rem);
      display: flex;
      align-items: center;
    }
  }

</style>
